var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-main"},[(_vm.type==1)?[_c('vxe-table',{staticClass:"mytable-scrollbar forms",attrs:{"data":_vm.list,"stripe":"","highlight-hover-row":"","size":"small"}},[_c('vxe-column',{attrs:{"type":"seq","title":"序号","width":"65"}}),_c('vxe-column',{attrs:{"field":"invite_name","title":"组织名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('textOver',{attrs:{"text":row.invite_name}})]}}],null,false,678909620)}),_c('vxe-column',{attrs:{"field":"invite_code","title":"统一社会信用代码"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('textOver',{attrs:{"text":row.invite_code}})]}}],null,false,1729481022)}),_c('vxe-column',{attrs:{"field":"invite_contact_name","title":"管理员姓名"}}),_c('vxe-column',{attrs:{"field":"invite_contact_mobile","title":"联系方式"}}),_c('vxe-column',{attrs:{"field":"join_date","title":"提交时间"}})],1)]:_vm._e(),(_vm.type==2)?[_c('searchHeader',{staticClass:"search-box",attrs:{"model":_vm.inputs},on:{"search":_vm.searchBtn}}),_c('vxe-table',{staticClass:"mytable-scrollbar forms",attrs:{"data":_vm.list,"stripe":"","highlight-hover-row":"","size":"small"}},[_c('vxe-column',{attrs:{"type":"seq","title":"序号","width":"65"}}),_c('vxe-column',{attrs:{"field":"invite_name","title":"组织名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('textOver',{attrs:{"text":row.invite_name}})]}}],null,false,678909620)}),_c('vxe-column',{attrs:{"field":"invite_code","title":"统一社会信用代码"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('textOver',{attrs:{"text":row.invite_code}})]}}],null,false,1729481022)}),_c('vxe-column',{attrs:{"field":"invite_contact_name","title":"管理员姓名"}}),_c('vxe-column',{attrs:{"field":"invite_contact_mobile","title":"联系方式"}}),_c('vxe-column',{attrs:{"field":"join_date","title":"提交时间"}})],1),_c('pageNumber',{attrs:{"pages":_vm.pages},on:{"changePage":_vm.changePage}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }